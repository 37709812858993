import React, { useEffect, useRef, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import { Link, useNavigate } from "react-router-dom";
import { RegisterValid } from "../validations/RegisterValid";
import { register, sendCode, validateCodeResult } from "../services/Login";
import toast from "react-hot-toast";
import { useAuth } from "../../AuthContext";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authenticated, login } = useAuth();
  const intervalRef = useRef(null);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [regTimer, setRegTimer] = useState(0);
  const [timerText, setTimerText] = useState("");
  const [codeText, setCodeText] = useState("Get Code");
  const [disableContactValue, setDisableContactValue] = useState(false);
  const [disableGetCode, setDisableGetCode] = useState(true);
  const [registerFileds, setregisterFileds] = useState({
    password: "",
    confirmPassword: "",
    verificationCode: "",
    refrelId: "",
  });
  const [registerFiledError, setRegisterFiledError] = useState({
    password: "",
    confirmPassword: "",
    verificationCode: "",
    refrelId: "",
    contactValue: "",
  });
  const [passwordShow, setPasswordShow] = useState({
    eye: "bi-eye-slash",
    type: "password",
  });
  const [confirmPasswordShow, setConfirmPasswordShow] = useState({
    eye: "bi-eye-slash",
    type: "password",
  });
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [check, setCheck] = useState(false);
  const [checkError, setCheckError] = useState("");
  const [disableInpute, setDisableInpute] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [automateCountryCode, setAutomateCountryCode] = useState("us");
  const [inputMobile, setInputMobile] = useState("");

  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    setAutomateCountryCode(
      data.country_code ? data.country_code.toLowerCase() : "us"
    );
  };

  useEffect(() => {
    if (!authenticated) {
      navigate("/register", { replace: true });
    } else {
      navigate("/dashboard", { replace: true });
    }
    getData();
  }, [authenticated, navigate]);

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const startCountdown = (seconds) => {
    let counter = seconds;
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setRegTimer(counter);
        setTimerText("Resend In");
        setBtnDisabled(true);
        setDisableGetCode(true);
        setDisableContactValue(true);
        counter--;
        if (counter < 0) {
          clearInterval(intervalRef.current);
          setBtnDisabled(false);
          setCodeText("Resend Code");
          setTimerText("");
          setDisableGetCode(false);
          setDisableContactValue(false);
        }
      }, 1000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "verificationCode") {
      if (!value) {
        setRegisterFiledError((prevErrors) => ({
          ...prevErrors,
          verificationCode: "This field is required",
        }));
      } else {
        setRegisterFiledError((prevErrors) => ({
          ...prevErrors,
          verificationCode: "",
        }));
      }
      const isNumeric = /^[0-9]+$/.test(value);
      if (value.length > 6) {
        return;
      }
      if (isNumeric && value.length === 6) {
        validateCode(value);
      }
    }
    setregisterFileds({ ...registerFileds, [name]: value });

    if (name === "password") {
      if (!value) {
        setRegisterFiledError((prevErrors) => ({
          ...prevErrors,
          password: "This field is required",
        }));
      } else {
        const emailvalidation = RegisterValid(name, value);
        if (emailvalidation) {
          setRegisterFiledError((prevErrors) => ({
            ...prevErrors,
            password: emailvalidation,
          }));
        } else {
          setRegisterFiledError((prevErrors) => ({
            ...prevErrors,
            password: "",
          }));
        }
      }
    }

    if (
      name === "password" &&
      registerFileds.confirmPassword &&
      registerFileds.confirmPassword !== value
    ) {
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Password does not match",
      }));
    } else {
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }

    if (name === "confirmPassword") {
      if (!value) {
        setRegisterFiledError((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "This field is required",
        }));
      } else {
        setRegisterFiledError((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));
      }

      if (value !== registerFileds.password) {
        setRegisterFiledError((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Password does not match",
        }));
      } else {
        setRegisterFiledError((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));
      }
    } else if (
      name === "confirmPassword" &&
      registerFileds.password &&
      registerFileds.password !== value
    ) {
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Password does not match",
      }));
    } else if (name !== "password" && name !== "confirmPassword") {
      // Clear the confirmPassword error if the user is typing in other fields
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }

    if (name === "refrelId") {
      // if (!value) {
      // setRegisterFiledError((prevErrors) => ({
      //   ...prevErrors,
      //   refrelId: "This field is required",
      // }));
      // } else {
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        refrelId: "",
      }));
      // }
    }
  };

  const validateCode = async (code) => {
    if (!mobile && !email) {
      setEmailErr("This field is required");
      return false;
    }
    const data = {
      filedType: selectedFiledType,
      currentValue: selectedFiledType === "email" ? email : mobile,
      otp: code,
    };
    const validateCodeRes = await validateCodeResult(data);
    if (validateCodeRes.status) {
      toast.success(t(validateCodeRes.message));
      setDisableGetCode(true);
      setDisableContactValue(true);
      setCodeText("Verified");
      setDisableInpute(false);
      clearInterval(intervalRef.current);
      setRegTimer(0);
      setTimerText("");
      setBtnDisabled(true);
    } else {
      toast.error(t(validateCodeRes.message));
      setCodeText("Resend Code");
      setDisableGetCode(false);
      setDisableContactValue(false);
    }
  };

  const showCurrentPassword = () => {
    if (passwordShow.type === "password") {
      setPasswordShow({ eye: "bi-eye", type: "text" });
    } else {
      setPasswordShow({ eye: "bi-eye-slash", type: "password" });
    }
  };

  const showConfirmPassword = () => {
    if (confirmPasswordShow.type === "password") {
      setConfirmPasswordShow({ eye: "bi-eye", type: "text" });
    } else {
      setConfirmPasswordShow({ eye: "bi-eye-slash", type: "password" });
    }
  };

  const handleCheck = (e) => {
    const { checked } = e.target;
    if (checked === false) {
      setCheckError("Please select terms & conditions");
    } else {
      setCheckError("");
    }
    setCheck(checked);
  };

  const onRegister = async (e) => {
    e.preventDefault();

    if (
      (!mobile || !email) &&
      !registerFileds.password &&
      !registerFileds.confirmPassword &&
      // !registerFileds.refrelId &&
      !registerFileds.verificationCode
    ) {
      let errors = {};

      for (let key in registerFileds) {
        errors[key] = "This field is required";
      }
      setEmailErr("This field is required");

      setRegisterFiledError({
        ...registerFiledError,
        ...errors,
      });
    } else {
      for (let key in registerFileds) {
        const checkLogin = RegisterValid(key, registerFileds[key]);
        setRegisterFiledError({ ...registerFiledError, [key]: checkLogin });

        if (checkLogin !== "") {
          return false;
        }
      }
    }

    if (check === false) {
      setCheckError("Please select terms & conditions");
      return false;
    }

    const RegisterData = {
      contactValue: selectedFiledType === "email" ? email : mobile,
      password: registerFileds.password,
      confirm_password: registerFileds.confirmPassword,
      verification_code: registerFileds.verificationCode,
      refer_id: registerFileds.refrelId,
      broker: "EBSWare",
      filedType: selectedFiledType,
    };
    const result = await register(RegisterData);
    if (result.status) {
      let token = result.token;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("userType", result.data.role);
      localStorage.setItem("isUserOnBoard", false);
      login();
      toast.success(t(result.message));
      setTimeout(function () {
        navigate("/dashboard", { replace: true });
      }, 2000);
      return false;
    } else {
      toast.dismiss();
      toast.error(t(result.message));
      return;
    }
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    if (!mobile && !email) {
      setEmailErr("This field is required");
      return false;
    }
    if (selectedFiledType === "email") {
      if (
        !/^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/i.test(
          email
        )
      ) {
        setEmailErr("Please enter a valid email address");
        return false;
      }
    }
    if (selectedFiledType === "mobile" && mobile) {
      const isValidLength = mobile.length >= 7 && mobile.length <= 15;
      if (!isValidLength) {
        setMobileErr("Please enter a valid mobile number");
        return false;
      } else {
        setMobileErr("");
      }
    }
    setBtnDisabled(true);
    const otpData = {
      filedType: selectedFiledType,
      contactValue: selectedFiledType === "email" ? email : mobile,
    };
    const result = await sendCode(otpData);
    if (result.status) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      startCountdown(30);
      toast.success(t(result.message));
    } else {
      toast.error(t(result.message));
      setBtnDisabled(false);
      return;
    }
  };

  const handleOnChange = (value, country) => {
    const effectiveValue = value.replace(/[\s\+]/g, "");
    const isEffectivelyEmpty = effectiveValue === country.dialCode;
    setEmailErr("");
    if (isEffectivelyEmpty) {
      setSelectedFiledType("email");
      setMobile("");
      setEmailErr("This field is required");
    } else {
      setSelectedFiledType("mobile");
      setEmailErr("");
    }
    setCodeText("Get Code");
    setMobile(value);
  };

  const handleObBlur = (e) => {
    const { name } = e.target;
    if (name === "email" && email === "") {
      setEmailErr("This field is required");
    } else if (name === "mobile" && mobile === "") {
      setMobileErr("This field is required");
    } else if (
      name === "verificationCode" &&
      registerFileds.verificationCode === ""
    ) {
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        verificationCode: "This field is required",
      }));
    } else if (name === "password" && registerFileds.password === "") {
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        password: "This field is required",
      }));
    } else if (
      name === "confirmPassword" &&
      registerFileds.confirmPassword === ""
    ) {
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "This field is required",
      }));
    } else if (name === "refrelId" && registerFileds.refrelId === "") {
      // setRegisterFiledError((prevErrors) => ({
      //   ...prevErrors,
      //   refrelId: "This field is required",
      // }));
    } else if (name !== "password" && name !== "confirmPassword") {
      // Clear the confirmPassword error if the user is typing in other fields
      setRegisterFiledError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };

  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const isEmail = /\S+@\S+\.\S+/.test(value);
      const isMobile = /^\d+$/.test(value);
      if (isMobile) {
        setSelectedFiledType("mobile");
        setInputMobile(value);
        setMobile(value);
        setEmailErr("");
        return;
      } else if (isEmail) {
        setSelectedFiledType("email");
      }
      setEmail(value);
      setCodeText("Get Code");
      const emailvalidation = RegisterValid(name, value);
      setEmailErr(emailvalidation);
      setMobileErr("");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login_form p70">
        <div className="container">
          <div className="row">
            <div className="col-md-5 m-auto">
              <div className="bg3 p-3 p-md-4 br20">
                <h3 className="mb-3">{t("Sign Up")}</h3>
                <form className="">
                  <div className="form-group">
                    <label>{t("Email or Mobile")}</label>
                    {selectedFiledType === "mobile" ? (
                      <PhoneInput
                        key={"phoneInput"}
                        country={automateCountryCode} // Default country (you can change it based on user's location)
                        value={inputMobile}
                        onChange={handleOnChange}
                        className=""
                        placeholder={t("Email/Mobile")}
                        countryCodeEditable={false}
                        enableSearch={true}
                        inputProps={{
                          autoFocus: true,
                          name: "mobile",
                        }}
                        disabled={disableContactValue}
                      />
                    ) : (
                      <input
                        key={"regularInput"}
                        className="form-control"
                        autoComplete="off"
                        type="text"
                        autoFocus
                        name="email"
                        id="email"
                        placeholder={t("Enter Email or Mobile Number")}
                        value={email}
                        onChange={handleChangeEmail}
                        onBlur={handleObBlur}
                        disabled={disableContactValue}
                        onPaste={(e) => e.preventDefault()}
                      />
                    )}
                    {emailErr && (
                      <span className="text-danger">{t(emailErr)}</span>
                    )}
                    {mobileErr && (
                      <span className="text-danger">{t(mobileErr)}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>{t("Verification Code")}</label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        autoComplete="off"
                        type="number"
                        name="verificationCode"
                        id="verificationCode"
                        placeholder={t("Verification Code")}
                        value={registerFileds.verificationCode}
                        onChange={handleChange}
                        onBlur={handleObBlur}
                        disabled={regTimer > 0 ? false : disableGetCode}
                        onKeyPress={handleKeyPress}
                      />
                      <button
                        className="btn position-absolute verification_btn"
                        type="submit"
                        onClick={sendOtp}
                        disabled={btnDisabled}
                      >
                        {t(timerText)}
                        {"  "}
                        {regTimer === 0 ? t(codeText) : t(regTimer)}
                      </button>
                    </div>
                    <span className="text-danger">
                      {t(registerFiledError.verificationCode)}
                    </span>
                  </div>
                  <div className="form-group">
                    <label>{t("Password")}</label>
                    <div className="eye_pass position-relative">
                      <input
                        className="form-control"
                        autoComplete="off"
                        type={passwordShow.type}
                        name="password"
                        id="password"
                        placeholder={t("Enter Password")}
                        value={registerFileds.password}
                        onChange={handleChange}
                        onBlur={handleObBlur}
                        disabled={disableInpute}
                      />
                      <span
                        className="password__show position-absolute eye1"
                        onClick={showCurrentPassword}
                      >
                        <i className={`bi ${passwordShow.eye}`}></i>
                      </span>
                      <span className="text-danger">
                        {t(registerFiledError.password)}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("Confirm Password")}</label>
                    <div className="eye_pass position-relative">
                      <input
                        className="form-control"
                        autoComplete="off"
                        type={confirmPasswordShow.type}
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder={t("Enter Confirm  Password")}
                        value={registerFileds.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleObBlur}
                        disabled={disableInpute}
                      />
                      <span
                        className="password__show position-absolute eye1"
                        onClick={showConfirmPassword}
                      >
                        <i className={`bi ${confirmPasswordShow.eye}`}></i>
                      </span>
                      <span className="text-danger">
                        {t(registerFiledError.confirmPassword)}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("Introducing Broker ID (Optional)")}</label>
                    <div className="eye_pass position-relative">
                      <input
                        className="form-control"
                        autoComplete="off"
                        type="text"
                        name="refrelId"
                        id="refrelId"
                        placeholder={t("Enter Introducing Broker ID (Optional)")}
                        value={registerFileds.refrelId}
                        onChange={handleChange}
                        onBlur={handleObBlur}
                        disabled={disableInpute}
                      />
                      <span className="text-danger">
                        {t(registerFiledError.refrelId)}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        onChange={handleCheck}
                        value={check}
                        disabled={disableInpute}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        {t("Terms And Condition")}
                      </label>
                    </div>
                    <span className="text-danger">{t(checkError)}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group log-btn mx-auto">
                      <button
                        className="btn w100"
                        type="submit"
                        onClick={onRegister}
                        disabled={disableInpute}
                      >
                        {t("Signup")}
                      </button>
                    </div>
                  </div>

                  <div className="form-group text-center pt-2">
                    {t("Already have an account?")}
                    <Link className="color1" to={"/login"}>
                      {t("Login")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
